import React, { useEffect } from 'react';
import TableData from '@atoms/TableData';
import { IChanges, IDictFilter, IDocumentTable } from '@models/Forms/IForms';
import { IField } from '@models/IFormData';
import { IValidHandle } from '@models/IValidHandle';
import { UseFormReturn } from 'react-hook-form';
import { deepEqual, getValueByPath } from '@utils/helpers';
import { IDictionaryData } from '@/models/dictionary/IDictionaryData';

export type ITableControlProps<TFieldValues extends object = object> = {
    table: IDocumentTable;
    docId?: string;
    allowUpdating: boolean;
    calculateRow: (row: any, column: any, table: IDocumentTable) => Promise<any>;
    onChangeCellValue: (
        row: any,
        oldRow: any,
        column: any,
        table: IDocumentTable,
        withOutUpdate: boolean,
    ) => Promise<void>;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    editCellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    evalTableFormulaValue: (condition: string, rowData?: any, rowParent?: any) => Promise<boolean>;
    onInitNewRow?: (row: any, table: IDocumentTable) => Promise<void>;
    onInitCopyRow?: (row: any, table: IDocumentTable) => Promise<void>;
    getColumnWatches: (table?: IDocumentTable, rowParent?: any) => string[];
    getWatchesByFormula: (formulas?: string[], rowParent?: any) => string[];
    onTableRowDeleted: (value: any, row: any, table: IDocumentTable) => Promise<void>;
    onTableRowCopied: (value: any, row: any, table: IDocumentTable) => Promise<void>;
    onFormRowEdited: (value: any, row: any, table: IDocumentTable) => Promise<void>;
    getParentFields: () => IField[];
    setParentField: (field: IField) => void;
    formMethods: UseFormReturn<TFieldValues>;
    name: string;
    onSaved: (data: any) => Promise<void>;
    getFormValuesAsync?: () => Promise<string>;
    getFiltersAsync?: () => Promise<IDictFilter>;
    onSetFormDataNewRow?: (item: any, table: IDocumentTable, data: IDictionaryData) => Promise<void>;
    onValidateExternalRowsData?: (data: IDictionaryData[], table: IDocumentTable) => Promise<boolean>;
};

const TableControl = <TFieldValues extends object = object>({
    name,
    docId,
    table,
    allowUpdating,
    calculateRow,
    onChangeCellValue,
    cellRenderSwitcher,
    editCellRenderSwitcher,
    evalTableFormulaValue,
    onInitNewRow,
    onInitCopyRow,
    getColumnWatches,
    onTableRowDeleted,
    onTableRowCopied,
    onFormRowEdited,
    getParentFields,
    setParentField,
    getWatchesByFormula,
    formMethods,
    onSaved,
    getFormValuesAsync,
    getFiltersAsync,
    onSetFormDataNewRow,
    onValidateExternalRowsData,
}: ITableControlProps<TFieldValues>) => {
    const ctl = React.useRef<IValidHandle>(null);
    const prevValue = React.useRef<any>(undefined);
    let valueData = formMethods.getValues(name as any);

    useEffect(() => {
        const unsubscribe = formMethods.watch((value: any) => {
            let data = getValueByPath(value, name);
            if (data) {
                if (!deepEqual(data, prevValue.current)) {
                    let copy = [...data];
                    prevValue.current = copy;
                    ctl.current?.setData(copy);
                }
            }
        });
        return () => unsubscribe.unsubscribe();
    }, [formMethods.watch]);

    const checkValid = (e: any) => {
        try {
            let status = ctl.current?.valid();
            console.log('TableControl: ' + status);
            return status;
        } catch (error) {
            console.log(error);
            return true;
        }
    };

    useEffect(() => {
        formMethods.register(name as any, { validate: { checkValid } });
    }, [formMethods.register]);

    return (
        <TableData
            ref={ctl}
            key={name + table.key}
            name={name + table.key}
            docId={docId}
            table={table}
            allowUpdating={allowUpdating}
            calculateRow={calculateRow}
            onTableRowDeleted={onTableRowDeleted}
            onTableRowCopied={onTableRowCopied}
            onFormRowEdited={onFormRowEdited}
            onChangeCellValue={onChangeCellValue}
            cellRenderSwitcher={cellRenderSwitcher}
            editCellRenderSwitcher={editCellRenderSwitcher}
            evalTableFormulaValue={evalTableFormulaValue}
            value={valueData === undefined ? [] : (valueData as any[])}
            getParentFields={getParentFields}
            getWatchesByFormula={getWatchesByFormula}
            setParentField={setParentField}
            onInitNewRow={onInitNewRow}
            onInitCopyRow={onInitCopyRow}
            getColumnWatches={getColumnWatches}
            getFiltersAsync={getFiltersAsync}
            getFormValuesAsync={getFormValuesAsync}
            onSetFormDataNewRow={onSetFormDataNewRow}
            onValidateExternalRowsData={onValidateExternalRowsData}
            onChanged={async (e, valid: boolean) => {
                let copy = [...e];
                prevValue.current = copy;
                await onSaved(e);
            }}
        />
    );
};

export default TableControl;
