import { AuthService } from '@services/AuthService';
import { AuthSlice, IAuthState } from '@/store/reducers/AuthReducer';
import { AppDispatch } from '@/store/index';
import history from '@/router/history';
import { UserService } from '@services/UserService';
import { RouteNames } from '@/router';
import { getReturnUrl } from '@utils/helpers';
import { TwoFactorAuthenticationService } from '@/services/TwoFactorAuthenticationService';
import { SamlAuthService } from '@/services/SamlAuthService';
import { AxiosError } from 'axios';

export const AuthActionCreators = {
    login: (username: string, password: string, remember: boolean) => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoggingIn(true));

            const response = await AuthService.login(username, password);

            let returnUrl = getReturnUrl();

            localStorage.setItem('frontAccessToken', response.data.accessToken);

            if (remember) {
                localStorage.setItem('frontRefreshToken', response.data.refreshToken);
            } else {
                localStorage.removeItem('frontRefreshToken');
            }

            dispatch(
                AuthSlice.actions.successLogin({
                    accessToken: response.data.accessToken,
                    refreshToken: response.data.refreshToken,
                    user: response.data.user,
                    returnUrl: returnUrl,
                } as IAuthState),
            );
        } catch (e) {
            console.log(e);
            let error = e as string;
            dispatch(AuthSlice.actions.setError(error));
        } finally {
            dispatch(AuthSlice.actions.setIsLoggingIn(false));
        }
    },

    loginByHeader: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoggingIn(true));

            const response = await AuthService.loginByHeader();

            if (!response) {
                throw 'Не удалось выполнить вход через SSO';
            }

            let returnUrl = getReturnUrl();

            localStorage.setItem('frontAccessToken', response.data.accessToken);
            localStorage.setItem('frontRefreshToken', response.data.refreshToken);
            dispatch(
                AuthSlice.actions.successLogin({
                    accessToken: response.data.accessToken,
                    refreshToken: response.data.refreshToken,
                    user: response.data.user,
                    returnUrl: returnUrl,
                } as IAuthState),
            );
        } catch (e) {
            console.log(e);
            let error = e as string;
            dispatch(AuthSlice.actions.setError(error));
        } finally {
            dispatch(AuthSlice.actions.setIsLoggingIn(false));
        }
    },

    loginBySaml: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoggingIn(true));

            const response = await SamlAuthService.loginAsync();

            if (!response) {
                throw 'Не удалось выполнить вход через ADFS';
            }

            let returnUrl = getReturnUrl();

            localStorage.setItem('frontAccessToken', response.data.accessToken);
            localStorage.setItem('frontRefreshToken', response.data.refreshToken);
            dispatch(
                AuthSlice.actions.successLogin({
                    accessToken: response.data.accessToken,
                    refreshToken: response.data.refreshToken,
                    user: response.data.user,
                    returnUrl: returnUrl,
                } as IAuthState),
            );
        } catch (e) {
            console.log(e);
            if (e instanceof AxiosError) {
                if (typeof e.response?.data === 'object') {
                    dispatch(AuthSlice.actions.setError(JSON.stringify(e.response?.data)));
                } else {
                    dispatch(AuthSlice.actions.setError(e.response?.data as string));
                }
            } else if (typeof e === 'object') {
                dispatch(AuthSlice.actions.setError(JSON.stringify(e)));
            } else {
                dispatch(AuthSlice.actions.setError(e as string));
            }
        } finally {
            dispatch(AuthSlice.actions.setIsLoggingIn(false));
        }
    },

    logout: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoading(true));
            await AuthService.logout();
            localStorage.removeItem('frontAccessToken');
            localStorage.removeItem('frontRefreshToken');
            dispatch(AuthSlice.actions.successLogout());
            history.push(RouteNames.LOGIN);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(AuthSlice.actions.setIsLoading(false));
        }
    },

    logoutBySaml: () => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoading(true));
            localStorage.removeItem('frontAccessToken');
            localStorage.removeItem('frontRefreshToken');
            dispatch(AuthSlice.actions.successLogout());
        } catch (e) {
            console.log(e);
        }
    },

    getCurrentUser: (accessToken: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(AuthSlice.actions.setIsLoading(true));
            const response = await UserService.getCurrentUser();
            if (response) {
                dispatch(
                    AuthSlice.actions.successLogin({
                        accessToken: accessToken,
                        user: response.data,
                    } as IAuthState),
                );
            }
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(AuthSlice.actions.setIsLoading(false));
        }
    },

    setIsLoading: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(AuthSlice.actions.setIsLoading(flag));
    },

    setIsLoggingIn: (flag: boolean) => async (dispatch: AppDispatch) => {
        dispatch(AuthSlice.actions.setIsLoggingIn(flag));
    },

    updateUserDepartment: (depId: string, depName: string) => async (dispatch: AppDispatch) => {
        dispatch(
            AuthSlice.actions.updateUserDepartment({
                depId: depId,
                depName: depName,
            }),
        );
    },
    resetReturnUrl: () => async (dispatch: AppDispatch) => {
        dispatch(AuthSlice.actions.resetReturnUrl());
    },

    loginTwoFactor:
        (service: TwoFactorAuthenticationService, username: string, password: string, login: () => void) =>
        (dispatch: AppDispatch) => {
            dispatch(AuthSlice.actions.setError(''));
            dispatch(AuthSlice.actions.setIsLoggingIn(true));
            dispatch(AuthSlice.actions.setIsSmsOtp(false));

            service.login(
                username,
                password,
                () => {
                    login();
                },
                (msg) => {
                    console.log(msg);
                    dispatch(AuthSlice.actions.setError(msg));
                    dispatch(AuthSlice.actions.setIsLoggingIn(false));
                },
                () => {
                    dispatch(AuthSlice.actions.setIsSmsOtp(true));
                },
            );
        },
};
