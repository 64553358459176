import React, { ReactNode, RefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './Dictpicker.scss';
import { Manager, Reference } from 'react-popper';
import { MdAdd, MdMenu, MdOutlineExpandMore } from 'react-icons/md';
import Input from '../Input';
import { classnames } from '@utils/classnames';
import DictpickerModal from './DictpickerModal/DictpickerModal';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import {
    IAddDictForm,
    IDictFilter,
    IDictSource,
    IExternalSearchDict,
    IGridModalAttributes,
    IJoinedDictionary,
    ISetValues,
} from '@models/Forms/IForms';
import DictpickerSelect from './DictpickerSelect/DictpickerSelect';
import DictChipItem from '@molecules/formbuilder/controls/Dict/DictChipItem/DictChipItem';
import Button from '@atoms/Button';

export interface IDictpickerRefActions {
    reset: () => void;
    setSelected: (item: string[]) => void;
}

export interface IDictpickerProps<T extends HTMLElement = HTMLDivElement> {
    /** Имя поля */
    name?: string;
    /** Имя справочника */
    dictionaryName: string;
    /** Псевдо-справочник */
    isFormData: boolean;
    isMultiple: boolean;
    isSelectMode: boolean;
    getExternalDataSource: () => any[];
    getFormValuesAsync: () => Promise<string>;
    getFiltersAsync: () => Promise<IDictFilter>;
    onValidate?: (data: IDictionaryData[]) => Promise<boolean>;
    /** зависимые поля*/
    setValues?: ISetValues;
    /** колонки для грида */
    gridAttribute?: IGridModalAttributes;
    /** Уровень загрузки справочника */
    startLevel?: number;
    /** Текст Placeholder */
    placeholder?: string;
    /** Значение по умолчанию */
    defaultValue?: any;
    /** отображаемое значение */
    diplayValue: string;
    /** Чистое значение поля */
    rawVal?: any;
    /** Скрыть поле */
    disabled?: boolean;
    /** Только для просмотра */
    readOnly?: boolean;
    /** Функция изменения значения */
    onChange?: (value: IDictionaryData[]) => void;
    /** Функция отмены */
    onCancel?: () => void;
    /** Кастомная кнопка */
    children?: ReactNode | ReactNode[];
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Сыылка на контейнер портала */
    containerRef?: RefObject<T>;
    /** Реф для пробрасывания действий по кастомным кнопкам */
    controlRef?: RefObject<IDictpickerRefActions | null>;
    /**
     *  Добавлять фокус при выборе дат
     * @default false
     *  */
    isFocusBorder?: boolean;
    insideGrid: boolean;
    /** Доступные для выбора уровни */
    selectableLevels?: string;
    /** Отображаемые уровни */
    visibleLevels?: string;
    /** Ключ кэша предикатов */
    predicatesCache?: string;
    externalSearch?: IExternalSearchDict;
    addForm?: IAddDictForm;
    /**
     * Режим загрузки справочника
     * */
    loadMode?: string;

    docId?: string;

    modalTitle?: string;

    joinedDictionaries?: IJoinedDictionary[];

    /**
     * Дополнительная настройка источника данных (выбор данных из реквизитов-таблиц и т.д.)
     */
    source?: IDictSource;
    /**
     * Флаг обработки операций фильтрации, поиска и т.п. на стороне клиента
     */
    useClientSideDataProcessing?: boolean;

    /** Отображение в виде чипсов */
    chipsView?: boolean;
}

// FIXME: Добавить управление с клавиатуры
const Dictpicker: React.FC<IDictpickerProps> = ({
    name = 'dictpicker',
    placeholder = 'Выберите значение',
    defaultValue,
    diplayValue,
    rawVal,
    gridAttribute,
    dictionaryName,
    invalid = false,
    filled = true,
    disabled = false,
    readOnly = false,
    onChange,
    onCancel,
    onValidate,
    children,
    isFormData,
    isMultiple,
    getFormValuesAsync: getFormValuesAsync,
    getFiltersAsync: getFiltersAsync,
    containerRef,
    controlRef,
    getExternalDataSource,
    isFocusBorder = false,
    insideGrid = false,
    selectableLevels = '',
    visibleLevels = '',
    predicatesCache = '',
    loadMode = '',
    useClientSideDataProcessing = false,
    isSelectMode,
    docId,
    externalSearch,
    addForm,
    modalTitle,
    joinedDictionaries,
    source,
    chipsView = false,
}: IDictpickerProps) => {
    useImperativeHandle(controlRef, () => ({
        reset: () => {},
        setSelected: (items) => {},
    }));

    // -------------------------------------------------------------------------------------------------------------------

    const dictpickerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [showDictionary, toggleDictionary] = useState<boolean>(false);

    const сancel = () => {
        toggleDictionary(false);
        onCancel && onCancel();
    };

    // -------------------------------------------------------------------------------------------------------------------

    const [inputValue, setInputValue] = useState<string[]>();

    const values = useMemo(() => {
        return typeof rawVal === 'string' && rawVal
            ? rawVal.split('|').sort((a, b) => {
                  const textA = a.toUpperCase();
                  const textB = b.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
            : [];
    }, [rawVal]);

    useEffect(() => {
        if (!defaultValue) {
            setInputValue(undefined);
        } else {
            if (source?.table) {
                let keys = (defaultValue as any[])?.map((row: any) => row[source.key]);
                if (keys) {
                    setInputValue(keys);
                }
            } else {
                if (defaultValue.toString().indexOf('|') > 0) {
                    setInputValue(defaultValue.toString().split('|'));
                } else {
                    let arr = [] as string[];
                    arr.push(defaultValue);
                    setInputValue(arr);
                }
            }
        }
    }, [defaultValue]);

    const toggleDict = (e?: any) => {
        toggleDictionary((prev) => !prev);
    };

    return (
        <Manager>
            <div
                className={classnames(
                    !insideGrid && 'rf-dictpicker',
                    insideGrid && 'rf-dictpicker-ingrid',
                    isFocusBorder && 'rf-dictpicker__focus-border',
                    disabled && 'rf-dictpicker--disabled',
                )}
                ref={dictpickerRef}
            >
                <Reference>
                    {(referenceProps) => (
                        <div
                            {...referenceProps}
                            className={classnames('rf-dictpicker__input-wrapper', {
                                'rf-dictpicker__input-wrapper--disabled': disabled,
                                'rf-dictpicker__input-wrapper--readonly': readOnly,
                            })}
                            onClick={!readOnly && !chipsView ? (e: any) => toggleDict(e) : () => {}}
                        >
                            {children ||
                                (chipsView ? (
                                    <div className="dict-chips-container">
                                        <div className="dict-chips-add">
                                            <Button
                                                size="s"
                                                buttonType={'round'}
                                                textColor="neutral"
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    toggleDict();
                                                }}
                                                className={readOnly ? 'dict-chips-add--disabled' : undefined}
                                                disabled={readOnly}
                                                startAdornment={<MdAdd size="24" />}
                                                aria-label="Добавить"
                                            />
                                        </div>
                                        <div className="dict-chips-items">
                                            {values &&
                                                values.length > 0 &&
                                                values.map((item: string, index: number) => (
                                                    <DictChipItem
                                                        key={index}
                                                        item={item}
                                                        showRemoveIcon={!readOnly}
                                                        onClick={
                                                            !readOnly
                                                                ? () => {
                                                                      toggleDict();
                                                                  }
                                                                : undefined
                                                        }
                                                        onRemove={() => {
                                                            onChange &&
                                                                onChange(
                                                                    values
                                                                        .filter((x) => x != item)
                                                                        .map((val) => {
                                                                            return { code: val } as IDictionaryData;
                                                                        }),
                                                                );
                                                        }}
                                                    />
                                                ))}
                                            {values === undefined || values.length === 0 ? (
                                                <div className="dict-chips-placeholder">{placeholder}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : (
                                    <Input
                                        name={name}
                                        value={diplayValue}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                        readOnly={true}
                                        withoutClasses={!readOnly}
                                        invalid={invalid}
                                        filled={filled}
                                        //nInputClick={!readOnly ? (e: any) => toggleDict(e) : () => {}}
                                        endAdornment={
                                            !readOnly && !disabled ? (
                                                <div className={'rf-dictpicker__calendar-chevron'}>
                                                    {isSelectMode ? <MdOutlineExpandMore /> : <MdMenu />}
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    />
                                ))}
                        </div>
                    )}
                </Reference>
                {showDictionary &&
                    (isSelectMode ? (
                        <DictpickerSelect
                            widthDropdown={dictpickerRef.current?.getBoundingClientRect().width}
                            selected={inputValue!}
                            docId={docId}
                            dictName={dictionaryName}
                            isFormData={isFormData}
                            getExternalDataSource={getExternalDataSource}
                            isMultiple={isMultiple}
                            selectableLevels={selectableLevels}
                            visibleLevels={visibleLevels}
                            predicatesCache={predicatesCache}
                            loadMode={loadMode}
                            useClientSideDataProcessing={useClientSideDataProcessing}
                            getFormValuesAsync={getFormValuesAsync}
                            getFiltersAsync={getFiltersAsync}
                            gridAttribute={gridAttribute}
                            onSubmitModal={onChange!}
                            onCloseModal={сancel}
                        />
                    ) : (
                        <DictpickerModal
                            selected={inputValue!}
                            docId={docId}
                            dictName={dictionaryName}
                            modalTitle={modalTitle}
                            isFormData={isFormData}
                            getExternalDataSource={getExternalDataSource}
                            isMultiple={isMultiple}
                            selectableLevels={selectableLevels}
                            visibleLevels={visibleLevels}
                            predicatesCache={predicatesCache}
                            externalSearch={externalSearch}
                            addForm={addForm}
                            loadMode={loadMode}
                            useClientSideDataProcessing={useClientSideDataProcessing}
                            getFormValuesAsync={getFormValuesAsync}
                            getFiltersAsync={getFiltersAsync}
                            gridAttribute={gridAttribute}
                            onSubmitModal={onChange!}
                            onCloseModal={сancel}
                            joinedDictionaries={joinedDictionaries}
                            onValidate={onValidate}
                        />
                    ))}
            </div>
        </Manager>
    );
};

export default Dictpicker;
