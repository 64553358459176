import React, { useEffect, useRef, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    MasterDetail,
    Paging,
    Scrolling,
    Selection,
    Sorting,
} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DevExpressDataGrid from '../DevExpress/DataGrid/DevExpressDataGrid';
import { Template } from 'devextreme-react/core/template';
import { Toolbar } from 'devextreme-react/toolbar';
import RowDetailsButton from './RowDetailsButton';
import { IField } from '@models/IFormData';
import { IActionService } from '@services/actions/IActionService';
import { IBaseAction } from '@models/actions/IBaseAction';
import { useParams } from 'react-router-dom';
import { classnames } from '@utils/classnames';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import ViewCellRenderSwitcher from './ViewCellRenderSwitcher';
import ActionButton from '@atoms/DevExpress/DataGrid/Buttons/ActionButton';
import OpenDocButton from './OpenDocButton';
import Tooltip from '@atoms/Tooltip';
import { MdHelpOutline } from 'react-icons/md';
import { exportGridToExcel } from '@utils/devextreme-react/dataGridUtils';

export interface ITableViewProps {
    value?: any[];
    table: IDocumentTable;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    visibilityColumn: (rules: string, rowParent?: any) => Promise<boolean>;
    getParentFields: () => IField[];
    actionService?: IActionService<IBaseAction>;
    onClickAction?: () => void;
    onRefreshExternalDS?: () => Promise<void>;
    docId?: string;
}

const TableView: React.FC<ITableViewProps> = ({
    value,
    table,
    cellRenderSwitcher,
    visibilityColumn,
    getParentFields,
    onClickAction,
    onRefreshExternalDS,
    actionService,
    docId,
}: ITableViewProps) => {
    const detailsTables: { [id: string]: IDocumentTable } = {};
    const activated = React.useRef(false);
    const { id } = useParams();
    const gridRef = useRef<DataGrid>(null);

    const renderMasterDetail = (tables: IDocumentTable[]) => {
        tables.forEach((table) => {
            detailsTables[table.key] = table;
        });
        return <MasterDetail enabled={true} render={renderDetailTable} />;
    };

    const [columns, setColumns] = useState<JSX.Element[]>();
    const [gWidth, setGWidth] = useState<number>();
    const InitColumns = async () => {
        let result: JSX.Element[] = [];
        let w = 50;
        for (let index = 0; index < table.tableColumn.length; index++) {
            const column = table.tableColumn[index];
            let vis = await checkVisRules(column);
            if (vis) {
                w = w + +column.width;
                result.push(await renderColumnGrid(column, `table_grid_${table.key}`));
            }
        }
        for (let index = 0; index < table.tableColumnDict.length; index++) {
            const column = table.tableColumnDict[index];
            let vis = await checkVisRules(column);
            if (vis) {
                w = w + +column.width;
                result.push(await renderColumnGrid(column, `table_dictgrid_${table.key}`));
            }
        }
        for (let index = 0; index < table.tableColumnAbook.length; index++) {
            const column = table.tableColumnAbook[index];
            let vis = await checkVisRules(column);
            if (vis) {
                w = w + +column.width;
                result.push(await renderColumnGrid(column, `table_abookgrid_${table.key}`));
            }
        }
        for (let index = 0; index < table.tableColumnCalc.length; index++) {
            const column = table.tableColumnCalc[index];
            let vis = await checkVisRules(column);
            if (vis) {
                result.push(await renderColumnGrid(column, `table_calcgrid_${table.key}`));
            }
        }
        for (let index = 0; index < table.tableColumnAutoComplete.length; index++) {
            const column = table.tableColumnAutoComplete[index];
            let vis = await checkVisRules(column);
            if (vis) {
                result.push(await renderColumnGrid(column, `table_autocompletegrid_${table.key}`));
            }
        }
        for (let index = 0; index < table.tableColumnExDataSource.length; index++) {
            const column = table.tableColumnExDataSource[index];
            let vis = await checkVisRules(column);
            if (vis) {
                w = w + +column.width;
                result.push(await renderColumnGrid(column, `table_calcgrid_${table.key}`));
            }
        }

        if (activated.current) {
            setGWidth(w);
            setColumns(result);
        }
        return null;
    };

    useEffect(() => {
        activated.current = true;
        InitColumns();
        return () => {
            activated.current = false;
        };
    }, []);

    const renderDetailTable = (param: any) => {
        let keys = Object.keys(detailsTables);

        return keys.map((key, i) => {
            let table = detailsTables[key];
            let val = param.data[key] ? param.data[key] : [];
            return (
                <TableView
                    key={i}
                    table={table}
                    value={val}
                    getParentFields={getParentFields}
                    cellRenderSwitcher={cellRenderSwitcher}
                    visibilityColumn={async (rules: string, rowParent?: any) => {
                        return await visibilityColumn(rules, param.data);
                    }}
                    docId={docId}
                />
            );
        });
    };

    const renderViewInFormActColumn = (table: IDocumentTable, rows: any[]) => {
        return (
            <Column
                key={`viewInForm`}
                width="36px"
                fixed={false}
                visibleIndex={0}
                encodeHtml={true}
                allowResizing={false}
                allowHiding={false}
                allowReordering={false}
                cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                cellRender={(p) => {
                    return (
                        <RowDetailsButton
                            getParentFields={getParentFields}
                            table={table}
                            rowData={p.data}
                            docId={docId}
                            rowArray={rows}
                        />
                    );
                }}
            />
        );
    };

    const renderViewActionColumn = (table: IDocumentTable) => {
        return (
            <Column
                key={`viewActions`}
                caption="Действия"
                visibleIndex={100}
                width="88px"
                allowResizing={false}
                allowHiding={false}
                alignment="center"
                allowReordering={false}
                cellTemplate="dxGridRowMenuCellTemplateAjax"
            />
        );
    };

    const renderOpenDocActColumn = (table: IDocumentTable) => {
        return (
            <Column
                key={`openDoc`}
                width="36px"
                fixed={false}
                visibleIndex={0}
                encodeHtml={true}
                allowResizing={false}
                allowHiding={false}
                allowReordering={false}
                cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                cellRender={(p) => {
                    return <OpenDocButton table={table} rowData={p.data} docId={docId} />;
                }}
            />
        );
    };

    const renderColumnGrid = async (column: any, path: string) => {
        return (
            <Column
                key={`column${path}${column.key}`}
                dataField={column.key}
                caption={column.name}
                width={column.width}
                minWidth={
                    // Если ширина в vw, то задаем мин ширину 5px
                    // У грида есть баг, он сравнивает width и minWidth только по цифре, без учета единиц
                    column.width ? (column.width.toString().endsWith('vw') ? 5 : undefined) : undefined
                }
                sortIndex={column.sortIndex}
                sortOrder={column.sortOrder}
                visibleIndex={column.order}
                encodeHtml={true}
                alignment={column.alignment}
                //TODO попросили убрать
                headerCellRender={(p) => {
                    return (
                        <div className="title-column-box">
                            <div
                                className={classnames(
                                    'title-column-caption',
                                    column.headerNoEllipsis && 'title-column-caption-noEllipsis',
                                )}
                            >
                                {p.column.caption}
                            </div>
                            {column.viewTitle && (
                                <div className="title-column-title">
                                    <Tooltip openDelay={100} background="black" position="bottom">
                                        <MdHelpOutline size="16" />
                                        {column.viewTitle}
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                }}
                cellRender={(p) => {
                    return <ViewCellRenderSwitcher data={p} column={column} cellRenderSwitcher={cellRenderSwitcher} />;
                }}
            ></Column>
        );
    };

    const getGridDataSource = (data: any[]) => {
        return new DataSource({
            store: new ArrayStore({
                key: '|NUM',
                data: data,
            }),
        });
    };

    const checkVisRules = async (column: any) => {
        return (
            column.visibilityRules == undefined ||
            column.visibilityRules == null ||
            (column.visibilityRules && (await visibilityColumn(column.visibilityRules)))
        );
    };

    const onExporting = () => {
        exportGridToExcel(gridRef, table.name || 'Экспорт таблицы');
    };

    return columns ? (
        <div className="form-table" data-testid={table.id ? `table-view-${table.id}` : undefined}>
            <DevExpressDataGrid
                ref={gridRef}
                columnMinWidth={30}
                allowColumnResizing={true}
                columnResizingMode="widget"
                dataSource={getGridDataSource(value!)}
                onCellHoverChanged={onCellHoverChanged}
                onExporting={onExporting}
            >
                <ColumnChooser enabled={false} />
                <Sorting mode="multiple" />
                <Scrolling useNative={true} />
                <Toolbar visible={false} />
                <Paging defaultPageSize={table.pageSize && table.pageSize > 0 ? table.pageSize : 20} />
                <FilterRow showOperationChooser={true} visible={table.allowFiltersRow} />
                {table.viewInForm && renderViewInFormActColumn(table, value!)}
                {table.previewDocByKey && renderOpenDocActColumn(table)}
                {columns}
                {table.tableColumnAction && table.tableColumnAction.linkDocumentAction && renderViewActionColumn(table)}
                {table.tables && table.tables.length > 0 && renderMasterDetail(table.tables)}

                <Export
                    enabled={table.allowExport}
                    allowExportSelectedData={table.allowExportSelectedRows}
                    formats={['xlsx']}
                    texts={{
                        exportAll: table.exportAllText || undefined,
                        exportSelectedRows: table.exportSelectedRowsText || undefined,
                        exportTo: table.exportToText || undefined,
                    }}
                />
                {table.allowExport && table.allowExportSelectedRows ? (
                    <Selection
                        allowSelectAll={true}
                        mode={'multiple'}
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />
                ) : null}

                <Template
                    name="dxGridRowMenuCellTemplateAjax"
                    render={function (data: any) {
                        let key = id + '/' + data.data[table.tableColumnAction.linkDocumentAction.linkKey];

                        return (
                            <ActionButton
                                objId={key}
                                service={actionService}
                                objData={data.data}
                                onActionClick={() => {
                                    onClickAction && onClickAction();
                                }}
                                onModifyData={() => {
                                    onRefreshExternalDS && onRefreshExternalDS();
                                    data.component.refresh();
                                }}
                            />
                        );
                    }}
                />
            </DevExpressDataGrid>
        </div>
    ) : (
        <></>
    );
};

export default TableView;
