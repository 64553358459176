import { UseFormReturn, useWatch } from 'react-hook-form';
import { IColumn } from '@models/Forms/IForms';
import Field from '../Field/Field';
import Block from '../Block/Block';

import Table from '../Table/Table';
import './Column.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import Dict from '../Dict/Dict';
import Row from '../Row/Row';
import Abook from '../Abook/Abook';
import Calc from '../Calc/Calc';
import Text from '../Text/Text';
import { handlerFieldWatch } from '@utils/documentUtils';
import AutoComplete from '../AutoComplete/AutoComplete';
import { IUniqueKey } from '@molecules/formbuilder/interfaces/IUniqueKey';
import LinksTable from '@molecules/formbuilder/controls/LinksTable/LinksTable';
import AttachmentsTable from '@molecules/formbuilder/controls/AttachmentsTable/AttachmentsTable';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import CustomComponent from '@molecules/formbuilder/controls/CustomComponent/CustomComponent';
import Url from '../Url/Url';
import EditInModal from '../EditInModal/EditInModal';
import TableLinks from '@molecules/formbuilder/controls/TableLinks/TableLinks';

export interface IColumnProps<TFieldValues extends object = object> extends IUniqueKey {
    column?: IColumn;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
    docScheme?: IDocumentScheme;
}

const Column = <TFieldValues extends object = object>({
    column,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    uniqueKey,
    docScheme,
    ...props
}: IColumnProps<TFieldValues>) => {
    let rules = column?.visibilityRules;
    const visibilityMng = new FormulaManager(rules!);
    visibilityMng.Init(fields, formMethods);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watch = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watch, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watch, isEdit, isNew]);

    return column && visibility ? (
        <div className="form-column" data-testid={column.id ? `column-${column.id}` : undefined}>
            {column.blocks &&
                column.blocks.map((item, i) => (
                    <Block
                        block={item}
                        formMethods={formMethods}
                        docId={docId}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                        uniqueKey={uniqueKey}
                        docScheme={docScheme}
                    />
                ))}
            {column.fields &&
                column.fields.map((item, i) => (
                    <Field
                        field={item}
                        formMethods={formMethods}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.tables &&
                column.tables.map((item, i) => (
                    <Table
                        table={item}
                        formMethods={formMethods}
                        docId={docId}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.tablesLinks &&
                column.tablesLinks.map((item, i) => (
                    <TableLinks
                        table={item}
                        formMethods={formMethods}
                        docId={docId}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.linksTables &&
                docScheme?.links?.tables &&
                column.linksTables.map((item, i) => (
                    <LinksTable
                        table={item}
                        tables={docScheme.links.tables}
                        formMethods={formMethods}
                        docId={docId}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                    />
                ))}
            {column.attachmentsTables &&
                docScheme?.attach?.tables &&
                column.attachmentsTables.map((item, i) => (
                    <AttachmentsTable
                        table={item}
                        tables={docScheme.attach.tables}
                        formMethods={formMethods}
                        docId={docId}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                    />
                ))}
            {column.dicts &&
                column.dicts.map((item, i) => (
                    <Dict
                        dict={item}
                        docId={docId}
                        formMethods={formMethods}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.texts &&
                column.texts.map((item, i) => (
                    <Text
                        text={item}
                        formMethods={formMethods}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                    />
                ))}
            {column.urls &&
                column.urls.map((item, i) => (
                    <Url
                        url={item}
                        formMethods={formMethods}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                    />
                ))}
            {column.editInModals &&
                column.editInModals.map((item, i) => (
                    <EditInModal
                        editInModal={item}
                        docId={docId}
                        formMethods={formMethods}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        setError={setError}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}

            {column.abooks &&
                column.abooks.map((item, i) => (
                    <Abook
                        abook={item}
                        formMethods={formMethods}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.rows &&
                column.rows.map((item, i) => (
                    <Row
                        row={item}
                        formMethods={formMethods}
                        docId={docId}
                        setError={setError}
                        fields={fields}
                        isEdit={isEdit}
                        isNew={isNew}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                        uniqueKey={uniqueKey}
                        docScheme={docScheme}
                    />
                ))}
            {column.calcs &&
                column.calcs.map((item, i) => (
                    <Calc
                        calc={item}
                        formMethods={formMethods}
                        setError={setError}
                        fields={fields}
                        isEdit={isEdit}
                        isNew={isNew}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.autoCompletes &&
                column.autoCompletes.map((item, i) => (
                    <AutoComplete
                        autoComplete={item}
                        formMethods={formMethods}
                        setError={setError}
                        fields={fields}
                        isEdit={isEdit}
                        isNew={isNew}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                    />
                ))}
            {column.customComponents &&
                docId !== undefined &&
                column.customComponents.map((item, i) => (
                    <CustomComponent
                        component={item}
                        formMethods={formMethods}
                        docId={docId}
                        isEdit={isEdit}
                        isNew={isNew}
                        fields={fields}
                        key={uniqueKey + i}
                    />
                ))}
        </div>
    ) : (
        <></>
    );
};

export default Column;
