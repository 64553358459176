import $api from '@/http/index';
import { TwoFactorSiAuthorizeResponse, TwoFactorValidationResponse } from '@/types/TwoFactorAuthentication.types';

class TwoFactorAuthenticationService {
    private _startedTimeout?: number;
    private _intervalTimeout?: number;
    private _requestTimeout?: number;
    private _limitTimeout?: number;

    private _initialDelay: number;
    private _interval: number;
    private _limit: number;
    private _success?: () => void;
    private _error?: (msg: string) => void;
    private _smsOtp?: () => void;

    private _authReqId?: string;
    public _isSmsOtp?: boolean;

    constructor(initialDelay: number = 1000, interval: number = 1000, limit: number = 120000) {
        this._initialDelay = initialDelay;
        this._interval = interval;
        this._limit = limit;
    }

    private stop() {
        if (this._startedTimeout) {
            clearTimeout(this._startedTimeout);
        }

        if (this._intervalTimeout) {
            clearTimeout(this._intervalTimeout);
        }

        if (this._requestTimeout) {
            clearTimeout(this._requestTimeout);
        }

        if (this._limitTimeout) {
            clearTimeout(this._limitTimeout);
        }
    }

    //начать мониторить подтверждение двухфакторной аутентификации
    private startMonitorValidation(authReqId: string) {
        this._authReqId = authReqId;
        this._isSmsOtp = false;
        this.stop();

        this._startedTimeout = setTimeout(() => {
            this.validationRequest(authReqId);
        }, this._initialDelay);

        this._limitTimeout = setTimeout(() => {
            this.stop();
            if (this._error) this._error('За отведенное время не получено подтверждение с мобильного устройства.');
        }, this._limit);
    }

    private validationRequest(authReqId: string) {
        $api.get<TwoFactorValidationResponse>(`/TwoFactorAuthentication/validate?authReqId=${authReqId}`)
            .then((res) => {
                if (res.data.isSmsOtp && !this._isSmsOtp) {
                    this._isSmsOtp = true;
                    if (this._smsOtp) this._smsOtp();
                }
                if (res.data.isValid) {
                    this.stop();
                    if (this._success) this._success();
                } else if (res.data.isError) {
                    this.stop();
                    if (this._error) this._error(res.data.error);
                } else {
                    this._requestTimeout = setTimeout(() => {
                        this.validationRequest(authReqId);
                    }, this._interval);
                }
            })
            .catch((ex) => {
                this.stop();
                if (this._error) this._error(ex);
            });
    }

    public login(
        username: string,
        password: string,
        success: () => void,
        error: (msg: string) => void,
        smsOtp: () => void,
    ) {
        this._success = success;
        this._error = error;
        this._smsOtp = smsOtp;

        $api.post<TwoFactorSiAuthorizeResponse>(`/TwoFactorAuthentication/SignIn`, {
            username: username,
            password: password,
        })
            .then((res) => {
                this.startMonitorValidation(res.data.auth_req_id);
            })
            .catch((ex) => {
                this.stop();
                if (this._error) this._error(ex);
            });
    }

    public sendSmsOtp(verifyCode: string) {
        this.stop();
        return $api
            .post(`/TwoFactorAuthentication/send-sms-otp`, { auth_req_id: this._authReqId, verify_code: verifyCode })
            .then((res) => {
                if (this._success) this._success();
            })
            .catch((ex) => {
                if (this._error) this._error(ex);
            });
    }
}

export { TwoFactorAuthenticationService };
