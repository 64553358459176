import { UseFormReturn } from 'react-hook-form';
import { IDocumentTableLinks, ISetValue } from '@models/Forms/IForms';
import { parseDisplayFormatCell } from '@utils/documentUtils';
import './ViewTableLinks.scss';
import { IField, IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import DisplayField from '@atoms/DisplayField/DisplayField';
import TableView from '@atoms/TableData/TableView';
import { GetValueForSetValue } from '@utils/ChangesManager';
import { EnvironmentService } from '@services/EnvironmentService';
import { RefresherDataSourceService } from '@services/RefresherDataSourceService';
import { ValueType } from '@/types/ValueType';
import { LinkedDocsActionsService } from '@services/actions/LinkedDocsActionsService';
import {
    isTableColumn,
    isTableColumnAbook,
    isTableColumnAutoComplete,
    isTableColumnCalc,
    isTableColumnDict,
} from '@utils/tableHelper';
import { sendErrorMsg } from '@molecules/Errors';

export interface IViewTableLinksProps<TFieldValues extends object = object> {
    table: IDocumentTableLinks;
    formMethods: UseFormReturn<TFieldValues>;
    fields: Record<string, IFieldElem>;
    docId?: string;
}

const ViewTableLinks = <TFieldValues extends object = object>({
    table,
    formMethods,
    fields,
    docId,
    ...props
}: IViewTableLinksProps<TFieldValues>) => {
    const activated = React.useRef(false);

    const [RefresherDataSource, setRefresherDataSource] = useState<RefresherDataSourceService>(
        table.extendsDataSource &&
            table.extendsDataSource.sqlProcedure &&
            table.extendsDataSource.sqlProcedure.refreshTimeOut
            ? new RefresherDataSourceService(
                  table.extendsDataSource.sqlProcedure.refreshTimeOut,
                  table.extendsDataSource.sqlProcedure.refreshTimeOut,
              )
            : new RefresherDataSourceService(),
    );
    const [isReady, setIsReady] = useState<boolean>(false);
    const [dsArray, setDsArray] = useState<any[]>();
    const actionService = new LinkedDocsActionsService();

    async function getSqlProcedureParam(params: ISetValue[]) {
        let result = {} as Record<string, any>;
        for (let index = 0; index < params.length; index++) {
            const param = params[index];
            let obj = {};
            result[param.key] = await GetValueForSetValue(param, undefined, fields, undefined, formMethods);
        }

        return JSON.stringify(result);
    }

    const replaceKeys = (obj: any) => {
        const allKeys = Object.keys(obj);
        const newObj: any = {};
        allKeys.forEach((key) => {
            if (!key.startsWith('|')) {
                newObj['|' + key] = obj[key];
            } else {
                newObj[key] = obj[key];
            }
        });
        return newObj;
    };

    const getParentFields = () => {
        let fields: IField[] = [];
        let values = formMethods.getValues() as any;
        let coll = values.fields as IField[];
        if (coll != undefined) {
            for (let index = 0; index < coll.length; index++) {
                const field_src = coll[index];
                let objCopy = { ...field_src };
                fields.push(objCopy);
            }
        }
        return fields;
    };

    const getExtendsData = async (key: string, params: string, ds: any[]) => {
        await EnvironmentService.getExtendData(key, params, true)
            .then((response) => {
                let data = response.data;
                let newArr: any[] = [];
                if (data) {
                    for (let index = 0; index < ds.length; index++) {
                        const obj = ds[index];
                        let objCopy = { ...obj };
                        let mElm = data.find((x) => {
                            return (
                                x[table.extendsDataSource.sqlProcedure.foreignKey] ==
                                obj[table.extendsDataSource.sqlProcedure.primaryKey]
                            );
                        });
                        if (mElm) {
                            let resObj = replaceKeys(mElm);
                            objCopy = { ...obj, ...resObj };
                        }
                        newArr.push(objCopy);
                    }
                }
                if (activated.current) {
                    fields[table.key].value = newArr;
                    setDsArray(newArr);
                    setIsReady(true);
                }
            })
            .catch((reason) => {
                sendErrorMsg({
                    message: [reason[0]],
                });
            });
    };

    const ExtendsDataSource = async (ds: any[]) => {
        if (table.extendsDataSource && table.extendsDataSource.sqlProcedure) {
            let params = await getSqlProcedureParam(table.extendsDataSource.sqlProcedure.params);
            await getExtendsData(table.extendsDataSource.sqlProcedure.name, params, ds);
            if (
                table.extendsDataSource.sqlProcedure.refreshTimeOut &&
                table.extendsDataSource.sqlProcedure.refreshTimeOut > 0
            ) {
                RefresherDataSource.startMonitor(() => {
                    getExtendsData(table.extendsDataSource.sqlProcedure.name, params, ds);
                });
            }
        } else {
            setIsReady(true);
        }
    };

    const Init = async () => {
        const dataField = fields[table.key];
        setDsArray(dataField.value);
        await ExtendsDataSource(dataField.value);
    };

    useEffect(() => {
        activated.current = true;
        Init();
        return () => {
            activated.current = false;
            if (RefresherDataSource) {
                RefresherDataSource.stop();
            }
        };
    }, []);

    useEffect(() => {
        Init();
    }, [fields]);

    const evalTableFormulaValue = async (condition: string, rowData?: any, rowParent?: any) => {
        let conditionMng = new FormulaManager(condition);
        conditionMng.Init(fields!, formMethods, rowParent);
        let coll = conditionMng.GetFields();
        let values: any[] | undefined = [];
        coll.forEach((f) => {
            if (f.includes('|Document')) {
                let field = fields[f];
                let fname = ('fields.[' + field.index + '].value') as any;
                let val = formMethods.getValues(fname);
                values?.push(val as any);
            } else if (f.includes('|Parent') && rowParent) {
                let fname = f.replace('|Parent', '');
                type ObjectKey = keyof typeof rowParent;
                const attrNAme = fname as ObjectKey;
                values?.push(rowParent[attrNAme] as any);
            } else {
                if (rowData) {
                    type ObjectKey = keyof typeof rowData;
                    const attrNAme = f as ObjectKey;
                    values?.push(rowData[attrNAme] as any);
                }
            }
        });
        if (values.every((element) => element === undefined)) {
            values = undefined;
        }

        return await conditionMng.EvalFormulaValues(false, false, values);
    };

    const cellRenderSwitcherField = async (p: any, column: any, rowParent?: any) => {
        let content = cellRenderSwitcher(p, column);
        let className =
            column.valueType === ValueType.LongText || column.valueType === ValueType.Base64Text ? 'longtext-cell' : '';
        className = column.valueType === ValueType.Text ? 'text-cell' : className;
        let cellClassName = 'table-cell';
        if (column.styles) {
            for (let index = 0; index < column.styles.style.length; index++) {
                const x = column.styles.style[index];
                let result = await evalTableFormulaValue(x.condition, p.data, rowParent);
                if (result) {
                    cellClassName += ' ' + x.cellStyle;
                }
            }
        }
        return (
            <div className={cellClassName}>
                <div className={className}>{content}</div>
            </div>
        );
    };

    const cellRenderSwitcher = (p: any, column: any) => {
        if (isTableColumnDict(column) || isTableColumnAbook(column)) {
            return <div>{parseDisplayFormatCell(column.displayFormat, p.data)}</div>;
        } else if (isTableColumnCalc(column)) {
            const typeValue = column.valueType !== undefined ? column.valueType : ValueType.Integer;
            return <DisplayField type={typeValue} value={p.value} />;
        } else if (isTableColumnAutoComplete(column)) {
            return <DisplayField type={ValueType.Text} value={p.value} />;
        } else if (isTableColumn(column)) {
            return (
                <DisplayField
                    type={column?.valueType}
                    value={p.value}
                    trueText={column?.trueText}
                    falseText={column?.falseText}
                />
            );
        }
    };

    const visibilityColumn = async (rules: string, rowParent?: any) => {
        let vis = new FormulaManager(rules);
        vis.Init(fields, formMethods, rowParent);
        return await vis.EvalFormulaValues(false, false, undefined);
    };

    return table && table.key && table.tableColumn && isReady && dsArray ? (
        <TableView
            value={dsArray}
            table={table}
            getParentFields={getParentFields}
            cellRenderSwitcher={cellRenderSwitcherField}
            visibilityColumn={visibilityColumn}
            onClickAction={() => {
                RefresherDataSource.stop();
            }}
            actionService={actionService}
            onRefreshExternalDS={async () => {
                await RefresherDataSource.doAction();
            }}
            docId={docId}
        />
    ) : (
        <></>
    );
};

export default ViewTableLinks;
